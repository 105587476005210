var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "full-calendar",
        {
          attrs: { events: _vm.fcEvents, lang: "zh" },
          on: { eventClick: _vm.fetchEvent, change: _vm.changeDateRange },
        },
        [
          _c("template", { slot: "fc-body-card" }, [
            _vm.interviewEvent
              ? _c(
                  "div",
                  { staticClass: "card-content clearfix" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "card-dropdown",
                        attrs: {
                          placement: "bottom-end",
                          "split-button": "",
                          size: "medium",
                        },
                      },
                      [
                        _vm._v(" 管理 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.checkStu(_vm.interviewEvent)
                                  },
                                },
                              },
                              [_vm._v("查看学员列表")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v("校区：" + _vm._s(_vm.interviewEvent.campus)),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v("上课时间："),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.lessonTime(_vm.interviewEvent)
                          ),
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v("老师：" + _vm._s(_vm.interviewEvent.teacher)),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "学生：" + _vm._s(_vm.interviewEvent.memberCount + "人")
                      ),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "状态：" + _vm._s(_vm.interviewEvent.lessonStatus)
                      ),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "课节名称：" +
                          _vm._s(_vm.interviewEvent.course) +
                          "/" +
                          _vm._s(_vm.interviewEvent.courseUnitTitles.title)
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("code-manage", {
        attrs: { show: _vm.dialogCode, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogCode = false
          },
        },
      }),
      _c("check-stu", {
        attrs: { lessonData: _vm.lessonData, show: _vm.dialogStu },
        on: {
          close: function ($event) {
            _vm.dialogStu = false
          },
        },
      }),
      _c("manage-lesson", {
        attrs: {
          show: _vm.lessonDialog,
          lessonId: _vm.lessonId,
          courseId: _vm.courseId,
          courseUnitIdList: _vm.courseUnitIdList,
          type: "试听",
        },
        on: {
          close: function ($event) {
            _vm.lessonDialog = false
            _vm.courseUnitIdList = []
            _vm.showCard = false
          },
          updateView: _vm.updateView,
        },
      }),
      _c("join-live", {
        attrs: { show: _vm.dialogJionLive, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogJionLive = false
          },
        },
      }),
      _c("adudition-lesson-student", {
        attrs: { show: _vm.lessonStudentDialog, lessonId: _vm.lessonId },
        on: {
          close: function ($event) {
            _vm.lessonStudentDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }