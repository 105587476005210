var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "60%",
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.taskTitle) } }),
        ]
      ),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active,
            "align-center": "",
            "finish-status": "success",
            simple: "",
          },
        },
        [
          _c("el-step", { attrs: { title: "分配试听学员" } }),
          _c("el-step", { attrs: { title: "创建直播间" } }),
        ],
        1
      ),
      _vm.seleTaskBtns
        ? _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.next } },
                  [_vm._v("下一步")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.saveTask },
                  },
                  [_vm._v("保存")]
                ),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }