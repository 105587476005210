var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.findPerms("forntbusiness:auditionRecord")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "试听记录", name: "first" } },
                [_c("audition-record", { ref: "record" })],
                1
              )
            : _vm._e(),
          _vm.findPerms("forntbusiness:auditionLesson")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "试听课表", name: "second" } },
                [_c("audition-lesson", { ref: "lesson" })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }