var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("file-header", {
        attrs: {
          ifTeacher: true,
          ifClassRoom: true,
          ifCourse: true,
          ifTime: true,
          ifShow: true,
        },
        on: {
          search: _vm.search,
          clearQuery: function ($event) {
            _vm.queryParam = { seleShow: "list" }
          },
          updateSeleShow: _vm.updateSeleShow,
          updateSeleShowTeacher: _vm.updateSeleShowTeacher,
          updateSeleShowCourse: _vm.updateSeleShowCourse,
          updateSeleShowStatus: _vm.updateSeleShowStatus,
          updateSeleShowName: _vm.updateSeleShowName,
          setTime: _vm.setTime,
        },
      }),
      _vm.seleShow === "list"
        ? _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              {
                staticClass: "flex",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("span", [_vm._v("试听课表")]),
                _c("div", { staticClass: "flex_1" }),
                _c("text-button", {
                  attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.search.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _c(
                  "el-table",
                  {
                    key: _vm.tableKey,
                    staticClass: "tb-list",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.dataList,
                      fit: "",
                      "default-sort": {
                        prop: "start_time",
                        order: "ascending",
                      },
                    },
                    on: { "sort-change": _vm.tableSortAll },
                  },
                  _vm._l(_vm.defaultHeader, function (item) {
                    return _c("el-table-column", {
                      key: item.label,
                      attrs: {
                        label: item.label,
                        align: item.align ? item.align : "left",
                        "min-width": item.width ? item.width : "auto",
                        sortable: item.sortable ? item.sortable : false,
                        prop: item.prop,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.label === "试听课内容"
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.course) +
                                            " /" +
                                            _vm._s(
                                              scope.row.courseUnitTitles.title
                                            )
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                item.label === "试听时间"
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateStart")(
                                              scope.row.startTime
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDateEnd")(
                                              scope.row.endTime
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  : item.label === "试听状态"
                                  ? [
                                      _c("span", {
                                        staticClass: "span-state",
                                        style:
                                          "backgroundColor" +
                                          ":" +
                                          _vm.getStateColor(
                                            scope.row.lessonStatus
                                          ),
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.lessonStatus)),
                                      ]),
                                    ]
                                  : item.label === "试听人数"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "underline",
                                            color: "#1c6ca1",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.queryStudent(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.memberCount) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : item.label === "操作"
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "has",
                                              rawName: "v-has",
                                              value: "frontbusiness:lessonEdit",
                                              expression:
                                                "'frontbusiness:lessonEdit'",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.gotoEditLesson(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      false
                                        ? _c(
                                            "el-dropdown",
                                            {
                                              staticStyle: {
                                                "margin-left": "25px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-dropdown-link",
                                                },
                                                [
                                                  _vm._v(" 更多"),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-arrow-down el-icon--right",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "el-dropdown-menu",
                                                {
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                [
                                                  Boolean(scope.row.liveId) &&
                                                  _vm.findHasAutho(
                                                    "frontbusiness:lessonLive"
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.jumpLive(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("去直播")]
                                                      )
                                                    : _vm._e(),
                                                  Boolean(scope.row.liveId)
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.codeManage(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("管理参加码")]
                                                      )
                                                    : _vm._e(),
                                                  !Boolean(scope.row.liveId) &&
                                                  _vm.findHasAutho(
                                                    "frontbusiness:lessonLive"
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addLive(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("新增直播间")]
                                                      )
                                                    : _vm._e(),
                                                  Boolean(scope.row.liveId)
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addLive(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("重建直播间")]
                                                      )
                                                    : _vm._e(),
                                                  Boolean(scope.row.liveId) &&
                                                  _vm.findHasAutho(
                                                    "frontbusiness:lessonLiveEdit"
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getLiveRoomtnById(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑直播间")]
                                                      )
                                                    : _vm._e(),
                                                  Boolean(scope.row.liveId) &&
                                                  _vm.findHasAutho(
                                                    "frontbusiness:lessonLiveDelete"
                                                  )
                                                    ? _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteLive(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除直播间")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row[item.prop])),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
                _c("el-pagination", {
                  staticStyle: { float: "right", "margin-right": "20px" },
                  attrs: {
                    background: "",
                    "current-page": _vm.pagination.currentPage,
                    "page-sizes": _vm.pagination.pageSizes,
                    "page-size": _vm.pagination.pageSize,
                    layout: _vm.pagination.layout,
                    total: _vm.pagination.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.seleShow !== "list"
        ? _c("lesson-calendar", {
            attrs: { calendarList: _vm.calendarList },
            on: { updateView: _vm.listTryLesson },
          })
        : _vm._e(),
      _c("add-adudition-detail", {
        attrs: {
          show: _vm.addAduditionDetailDialog,
          aduditionObject: _vm.aduditionObject,
        },
        on: {
          close: function ($event) {
            _vm.addAduditionDetailDialog = false
          },
          aduditionDetail: _vm.listTryLesson,
        },
      }),
      _c("adudition-lesson-student", {
        attrs: { show: _vm.lessonStudentDialog, lessonId: _vm.lessonId },
        on: {
          close: function ($event) {
            _vm.lessonStudentDialog = false
          },
        },
      }),
      _c("add-live", {
        attrs: {
          show: _vm.dialogLive,
          liveName: _vm.lessonLiveName,
          liveData: _vm.liveData,
          lessonData: _vm.lessonLiveData,
        },
        on: {
          close: function ($event) {
            ;(_vm.dialogLive = false),
              (_vm.liveData = null),
              (_vm.lessonLiveData = null)
          },
          addLiveSave: _vm.addLiveSave,
          editLiveSave: _vm.editLiveSave,
        },
      }),
      _c("code-manage", {
        attrs: { show: _vm.dialogCode, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogCode = false
          },
        },
      }),
      _c("join-live", {
        attrs: { show: _vm.dialogJionLive, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogJionLive = false
          },
        },
      }),
      _c("new-audition", {
        attrs: { show: _vm.newAuditionDialog },
        on: {
          close: function ($event) {
            _vm.newAuditionDialog = false
          },
        },
      }),
      _c("check-time", {
        attrs: { show: _vm.timeDialog },
        on: {
          close: function ($event) {
            _vm.timeDialog = false
          },
        },
      }),
      _c("room-time", {
        attrs: { show: _vm.timeRoomDialog },
        on: {
          close: function ($event) {
            _vm.timeRoomDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }