<template>
  <div>
    <!-- calendarList：{{calendarList}}
    <hr>
    fcEvents：{{fcEvents}} -->
    <!-- <hr> -->
    <full-calendar :events="fcEvents" @eventClick="fetchEvent" lang="zh" @change="changeDateRange" >
      <template slot="fc-body-card">
        <div class="card-content clearfix" v-if="interviewEvent">
          <el-dropdown class="card-dropdown" placement="bottom-end" split-button size="medium">
            管理
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="toClass(interviewEvent)">去上课</el-dropdown-item>
              <el-dropdown-item @click.native="jumpLive(interviewEvent)" :disabled="!Boolean(interviewEvent.liveId)">去直播</el-dropdown-item>
              <el-dropdown-item @click.native="manageLessTask(interviewEvent)">管理课堂任务</el-dropdown-item>
              <el-dropdown-item @click.native="codeManage(interviewEvent)" :disabled="!Boolean(interviewEvent.liveId)">管理参加码</el-dropdown-item> -->
              <el-dropdown-item @click.native="checkStu(interviewEvent)">查看学员列表</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <p class="card-info">校区：{{interviewEvent.campus}}</p>
          <!-- <p class="card-info">校区：{{interviewEvent}}</p> -->
          <p class="card-info">上课时间：<span v-text="lessonTime(interviewEvent)"></span></p>
          <p class="card-info">老师：{{interviewEvent.teacher}}</p>
          <p class="card-info">学生：{{interviewEvent.memberCount + '人'}}</p>
          <p class="card-info">状态：{{interviewEvent.lessonStatus}}</p>
          <p class="card-info">课节名称：{{interviewEvent.course}}/{{interviewEvent.courseUnitTitles.title}}</p>
        </div>
      </template>
    </full-calendar>
    <!-- 参加码管理 -->
    <code-manage
      :show="dialogCode"
      :liveRoomId="liveRoomId"
      @close="dialogCode=false"
    ></code-manage>

    <!-- 查看学员dialog -->
    <check-stu :lessonData="lessonData" :show="dialogStu" @close="dialogStu=false"></check-stu>

    <!-- 管理课堂任务 -->
    <manage-lesson :show="lessonDialog" @close="lessonDialog=false;courseUnitIdList=[];showCard=false" :lessonId="lessonId" :courseId="courseId" :courseUnitIdList="courseUnitIdList" @updateView="updateView" type="试听"></manage-lesson>

    <!-- 进入直播间 -->
    <join-live :show="dialogJionLive" @close="dialogJionLive=false" :liveRoomId="liveRoomId"></join-live>

<adudition-lesson-student :show="lessonStudentDialog" :lessonId="lessonId" @close="lessonStudentDialog = false"></adudition-lesson-student>
  </div>
</template>

<script>
import FullCalendar from '@/components/FullCalendar'
import { formatDate } from '@/utils/formatDate'
import codeManage from '@/components/senate/detail-pane/components/code-manage'
import aduditionLessonStudent from '@/components/frontbusiness/aduditionLessonStudent'
import joinLive from '@/components/senate/detail-pane/components/join-live'
import manageLesson from '@/components/senate/manage-lesson/index'
import checkStu from '@/components/senate/check-student/index'
import dateFunc from '@/utils/dateFunc'
import { listCourseUnit } from '@/api/senate/class'

export default {
  components: {
    FullCalendar,
    codeManage,
    joinLive,
    manageLesson,
    checkStu,
    aduditionLessonStudent
  },
  props: {
    calendarList: {
      default: [],
      type: Array
    }
  },
  watch: {
    calendarList: {
      handler(val) {
        // 转换一下目的是为了数据不被同步
        this.fcEvents = val
      },
      immediate: true,
      deep: true,
    },
  },
  data () {
    return {
      lessonStudentDialog:false,
      fcEvents: [],
      interviewEvent: null,
      showCard: false,
      dialogCode: false,
      dialogStu: false,
      lessonDialog: false,
      dialogJionLive: false,
      liveRoomId: '',
      lessonData: {},
      lessonId: '',
      courseId: '',
      courseUnitIdList: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fcEvents = this.calendarList
    })
  },
  methods: {
    fetchEvent (event, jsEvent) {
      this.interviewEvent = event
    },

    // 改变日期时间段
    changeDateRange (type, start, end, currentStart) {
      // console.log('type', type)
      // console.log('start', start)
      // console.log('end', end)
      // console.log('currentStart', currentStart)
      let currentEnd = dateFunc.format(dateFunc.getEndDate(new Date(currentStart)), 'yyyy-MM-dd')
      if (type === 'month') {
        this.$emit('updateView', currentStart, currentEnd)
      } else {
        this.$emit('updateView', start, end)
      }
    },

    // 上课时间
    lessonTime (item) {
      const start = formatDate(new Date(item.startTime), 'hh:mm')
      const end = formatDate(new Date(item.endTime), 'hh:mm')
      const lessonTime = `${start}~${end}`
      return lessonTime
    },

    // 去上课
    toClass (row) {
      if (!row.courseId) {
        window.$msg('您还未选择课堂任务，请先选择课堂任务', 2)
      } else {
        this.$router.push({
          path: '/senate/classroom-task',
          query: {
            // classId: row.class_id ? row.class_id : '',
            lessonId: row.lessonId,
            // courseId: row.courseId
          }
        })
      }
    },

    // 去直播
    jumpLive (row) {
      this.liveRoomId = row.liveId
      this.dialogJionLive = true
    },

    // 管理课堂任务
    manageLessTask (row) {
      listCourseUnit({
        lessonId: row.lessonId
      }).then(res => {
        if (res.state === 'success') {
          res.body.forEach((item, index) => {
            this.courseUnitIdList.push(item.id)
          })
        }
      })
      this.courseId = row.courseId
      this.lessonDialog = true
    },

    // 参加码管理
    codeManage (row) {
      this.liveRoomId = row.liveId
      this.dialogCode = true
    },

    // 查看学员列表
    checkStu (row) {
      this.lessonId = row.lessonId
      this.lessonStudentDialog = true
    },

    updateView () {
      this.$emit('updateView')
    },
  }
}
</script>

<style lang="scss" scoped>
.card-content {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  padding: 5px 10px 20px 10px;
  text-align: left;
  .card-info {
    font-size: 13px;
    color: #3e444c;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    word-wrap: none;
    padding-left: 10px;
  }
  .card-dropdown {
    font-size: 18px;
    text-overflow: ellipsis;
    float: right;
  }
}

/deep/ .el-button-group > .el-button:first-child {
  display: none;
}

/deep/ .el-button-group > .el-button:last-child {
  border-radius: 3px;
  border: 0;
}

/deep/ .el-dropdown .el-dropdown__caret-button::before {
  width: 0;
}

/deep/ .el-icon-arrow-down:before {
  content: "\e7bb";
  font-size: 18px;
}
</style>
