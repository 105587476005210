var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("file-header", {
        attrs: {
          ifName: true,
          ifLoginName: true,
          ifTeacher: true,
          ifClassRoom: true,
          ifCourse: true,
          ifStatus: true,
          ifConsultant: true,
        },
        on: {
          search: _vm.search,
          clearQuery: function ($event) {
            _vm.queryParam = {}
          },
          updateSeleShowTeacher: _vm.updateSeleShowTeacher,
          updateSeleShowCourse: _vm.updateSeleShowCourse,
          updateSeleShowStatus: _vm.updateSeleShowStatus,
          updateSeleShowName: _vm.updateSeleShowName,
          datetTimeGet: _vm.datetTimeGet,
        },
      }),
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c(
          "div",
          { staticClass: "flex", attrs: { slot: "header" }, slot: "header" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "has",
                    rawName: "v-has",
                    value: "audition:offline",
                    expression: "'audition:offline'",
                  },
                ],
                staticStyle: { float: "right" },
                attrs: { plain: "", id: "el-button1", type: "primary" },
                on: { click: _vm.LookTask },
              },
              [_vm._v("预约试听")]
            ),
            _c("div", { staticClass: "flex_1" }),
            _c("text-button", {
              attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
              nativeOn: {
                click: function ($event) {
                  return _vm.search.apply(null, arguments)
                },
              },
            }),
            _c("text-button", {
              attrs: {
                icon: "el-icon-setting",
                contentTit: "列设置",
                colSetting: _vm.colSetting,
                ispopover: true,
                baseColSetting: _vm.baseColSetting,
              },
              on: { update: _vm.update },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                key: _vm.tableKey,
                staticClass: "tb-list",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.dataList, fit: "", stripe: "" },
              },
              _vm._l(_vm.defaultHeader, function (item) {
                return _c("el-table-column", {
                  key: item.label,
                  attrs: {
                    label: item.label,
                    align: item.align ? item.align : "left",
                    "min-width": item.width ? item.width : "auto",
                    sortable: item.sortable ? item.sortable : false,
                    prop: item.prop,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.label === "试听课内容"
                              ? [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.course) +
                                        "/" +
                                        _vm._s(scope.row.courseUnitTitles.title)
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            item.label === "试听状态"
                              ? [
                                  _c("span", {
                                    staticClass: "span-state",
                                    style:
                                      "backgroundColor" +
                                      ":" +
                                      _vm.getTryStatusColor(
                                        scope.row.tryStatus
                                      ),
                                  }),
                                  scope.row.tryStatus === "承诺到访"
                                    ? _c("span", [_vm._v(" 已测试(待试听) ")])
                                    : scope.row.tryStatus === "已邀约"
                                    ? _c("span", [_vm._v(" 已邀约(待测试) ")])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.tryStatus) +
                                            " "
                                        ),
                                      ]),
                                ]
                              : item.label === "试听课上课时间"
                              ? [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateStart")(
                                          scope.row.startTime
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateEnd")(
                                          scope.row.endTime
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              : item.label === "操作"
                              ? [
                                  scope.row.tryStatus == "已预约"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmGet(
                                                scope.row,
                                                "cancel"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("取消预约")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.insertMark(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("试听备注")]
                                  ),
                                  false
                                    ? _c(
                                        "el-dropdown",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(" 更多"),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              !Boolean(scope.row.liveId)
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addTestLiveRoom(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("新增测试直播间")]
                                                  )
                                                : _vm._e(),
                                              scope.row.liveId
                                                ? _c(
                                                    "el-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addTestLiveRoom(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("重建测试直播间")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.prop])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
            _c("el-pagination", {
              staticStyle: { float: "right", "margin-right": "20px" },
              attrs: {
                background: "",
                "current-page": _vm.pagination.currentPage,
                "page-sizes": _vm.pagination.pageSizes,
                "page-size": _vm.pagination.pageSize,
                layout: _vm.pagination.layout,
                total: _vm.pagination.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c("add-live", {
        attrs: {
          show: _vm.dialogLive,
          liveName: _vm.lessonLiveName,
          liveData: _vm.liveData,
          isTest: true,
          lessonData: _vm.lessonLiveData,
        },
        on: {
          close: function ($event) {
            ;(_vm.dialogLive = false),
              (_vm.liveData = null),
              (_vm.lessonLiveData = null)
          },
          addLiveSave: _vm.addLiveSave,
          editLiveSave: _vm.editLiveSave,
        },
      }),
      _c("join-live", {
        attrs: { show: _vm.dialogJionLive, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogJionLive = false
          },
        },
      }),
      _c("code-manage", {
        attrs: { show: _vm.dialogCode, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogCode = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }