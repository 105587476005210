<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="12">
          <el-col :md="6" :sm="24" v-if="ifShow">
            <el-form-item label="">
              <el-select
                v-model="queryParam.seleShow"
                class="w100"
                placeholder="请选择展示形式"
                filterable
                @change="handleChange"
              >
                <el-option
                  v-for="item in showList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24" v-if="ifTime">
            <!-- 试听区间： -->
            <el-form-item label="">
              <date-picker  class="w100" 
              :dateTime="dateTime" 
              :disabled="queryParam.seleShow !== 'list'" 
              @updateView="setTime" ref="datePicker"
              ></date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24" v-if="ifName">
            <el-form-item label="">
              <el-input
                placeholder="请输入学员姓名/登录账号"
                v-model.trim="queryParam.stuLoginName"
                class="w100"
                clearable
                @change="handleChangeName"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="6" :sm="24">
            <el-form-item label="试听校区：">
              <el-select
                v-model="queryParam.campus_id"
                class="w100"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in schoolArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <template v-if="advanced">
            <el-col :md="6" :sm="24" v-if="ifLoginName">
              <date-picker
                ref="dataPicker"
                :dateTime="dateTimeTry"
                class="w100"
                @updateView="datetTimeGet"
              ></date-picker>
              <!-- <el-form-item label=""> -->
                <!-- <el-input
                  placeholder="请输入学生学号"
                  v-model.trim="queryParam.loginName"
                  class="w100"
                  clearable
                ></el-input> -->
              <!-- </el-form-item> -->
            </el-col>
            
            <el-col :md="6" :sm="24" v-if="ifStatus">
              <el-form-item label="">
                <el-select
                  v-model="queryParam.tryStatus"
                  class="w100"
                  placeholder="请选择试听状态"
                  clearable
                  filterable
                  @change="handleChangeStatus"
                >
                  <el-option
                    v-for="item in stateOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :md="6" :sm="24">
              <el-form-item label="教室名称：" v-if="ifClassRoom">
                <el-input
                  placeholder="请输入"
                  v-model.trim="queryParam.classroom"
                  class="w100"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :md="6" :sm="24" v-if="ifCourse">
              <el-form-item label="">
                <el-input
                  placeholder="请输入课程名称"
                  v-model.trim="queryParam.course"
                  class="w100"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :md="6" :sm="24" v-if="ifCourse">
              <el-form-item label="">
                <el-input
                  placeholder="请输入课节名称"
                  v-model.trim="queryParam.courseUnitTitle"
                  class="w100"
                  clearable
                  @change="handleChangeCourse"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :md="6" :sm="24">
              <el-form-item label="顾问名称：" v-if="ifConsultant">
                <el-input
                  placeholder="请输入"
                  v-model.trim="queryParam.consultant"
                  class="w100"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :md="6" :sm="24">
              <el-form-item label="" v-if="ifTeacher">
                <el-input
                  placeholder="请输入老师姓名"
                  v-model.trim="queryParam.teacher"
                  class="w100"
                  clearable
                  @change="handleChangeTeacher"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- 搜索 -->
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="searchQuery"
          ></search-query>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
  /**
* @author libin
* @description 学员档案公共检索模块组件 <file-header @search="search" @clearQuery="queryParam = {}"></file-header>
ifTeacher（是否显示教师名称检索）、ifClassRoom（是否显示教室名称检索）
* */
// import getMyCampus from '@/mixins/getMyCampus'
import SearchQuery from '@/components/SearchQuery'
import { formatDateYMD } from '@/utils/formatDate';
import datePicker from '@/components/date-picker'

export default {
  props: {
    ifShow: {
      default: false,
      type: Boolean
    },
    ifName: {
      default: false,
      type: Boolean
    },
    ifLoginName: {
      default: false,
      type: Boolean
    },
    ifTeacher: {
      default: false,
      type: Boolean
    },
    ifClassRoom: {
      default: false,
      type: Boolean
    },
    ifCourse: {
      default: false,
      type: Boolean
    },
    ifStatus: {
      default: false,
      type: Boolean
    },
    ifConsultant: {
      default: false,
      type: Boolean
    },
    ifTime: {
      default: false,
      type: Boolean
    }
  },
  // mixins: [getMyCampus],
  components: { SearchQuery,datePicker },
  created() {
    const end = new Date();
    const start = new Date();
    const weekday = start.getDay()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
    end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
    this.queryParam.time = [formatDateYMD(start),formatDateYMD(end)];
    this.dateTime = [formatDateYMD(start),formatDateYMD(end)];
    this.dateTimeTry =  [formatDateYMD(start),formatDateYMD(end)];
    this.queryParam.minStartTime = [formatDateYMD(start),formatDateYMD(end)][0];
    this.queryParam.maxStartTime = [formatDateYMD(start),formatDateYMD(end)][1];
  },
  data () {
    return {
      advanced: false,
      dateTime:[],
      dateTimeTry:[],
      queryParam: {
        seleShow: 'list',
        time:'',
        minStartTime:'',
        maxStartTime:'',
      },
      
      stateOptions: [
        // {
        //   value: '已邀约',
        //   label: '已邀约(待测试)'
        // },
        // {
        //   value: '承诺到访',
        //   label: '已测试(待试听)'
        // },
         {
          value: '已预约',
          label: '已预约'
        },
        {
          value: '已试听',
          label: '已试听'
        },
         {
          value: '未出勤',
          label: '未出勤'
        },
        // {
        //   value: '已取消',
        //   label: '已取消'
        // }
      ],
      showList: [
        {
          label: '按列表展示',
          value: 'list'
        },
        {
          label: '按日历展示',
          value: 'calendar'
        }
      ],
    }
  },
  methods: {
    setTime(data) {
      if (data === null) {
        this.queryParam.time = null
      } else {
        this.queryParam.time = data
      }
      this.$emit('setTime',this.queryParam.time)
    },
    updateView (data) {
      this.advanced = data
    },
    clearQuery () {
      this.queryParam = {
        seleShow: 'list'
      },
      this.$emit('clearQuery')
    },
    handleChange(val) {
      this.$emit('updateSeleShow', val)
    },
    handleChangeTeacher(val){
      this.$emit('updateSeleShowTeacher', val)
    },
    handleChangeCourse(val){
      this.$emit('updateSeleShowCourse', val)
    },
    handleChangeStatus(val){
      this.$emit('updateSeleShowStatus', val)
    },
    handleChangeName(val){
      this.$emit('updateSeleShowName', val)
    },
    handlePickerChange() {
      this.$forceUpdate()
    },
    datetTimeGet(data) {
      if (data === null) {
        this.queryParam.minStartTime = "";
        this.queryParam.maxStartTime = "";
      } else {
        this.queryParam.minStartTime = data[0];
        this.queryParam.maxStartTime = data[1];
      }
      this.$emit('datetTimeGet', this.queryParam.minStartTime,this.queryParam.maxStartTime)
    },
    searchQuery(){
      this.$emit('search', this.queryParam)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/container";
</style>
