<template>
  <el-row class="table">
    <!-- 表头 -->
    <file-header
      @search="search"
      @clearQuery="queryParam = {}"
      :ifName="true"
      :ifLoginName="true"
      :ifTeacher="true"
      :ifClassRoom="true"
      :ifCourse="true"
      :ifStatus="true"
      :ifConsultant="true"
      @updateSeleShowTeacher='updateSeleShowTeacher'
      @updateSeleShowCourse='updateSeleShowCourse'
      @updateSeleShowStatus='updateSeleShowStatus'
      @updateSeleShowName='updateSeleShowName'
      @datetTimeGet='datetTimeGet'
    ></file-header>
    <!-- 表格 -->
    <el-card shadow="never" >
      <div slot="header" class="flex">
        <el-button
          plain
          style="float: right"
          id='el-button1'
          type="primary"
          @click="LookTask"
          v-has="'audition:offline'"
        >预约试听</el-button
        >
        <div class="flex_1"></div>

        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <div>
        <el-table
          :key="tableKey"
          :data="dataList"
          class="tb-list"
          fit
          style="width: 100%"
          stripe
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '试听课内容'">
                <span>{{ scope.row.course }}/{{ scope.row.courseUnitTitles.title}}</span>
              </template>
              <template v-if="item.label === '试听状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getTryStatusColor(scope.row.tryStatus)
                  "
                ></span>
                <span v-if="scope.row.tryStatus === '承诺到访'">
                  已测试(待试听)
                </span>
                <span v-else-if="scope.row.tryStatus === '已邀约'">
                  已邀约(待测试)
                </span>

                <span v-else>
                  {{ scope.row.tryStatus }}
                </span>

              </template>
              <template v-else-if="item.label === '试听课上课时间'">
                <span>{{ scope.row.startTime | formatDateStart }}</span>
                <span>{{ scope.row.endTime | formatDateEnd }}</span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  v-if="scope.row.tryStatus == '已预约'"
                  type="text"
                  @click="confirmGet(scope.row, 'cancel')"
                  >取消预约</el-button
                >
                <el-button type="text" @click="insertMark(scope.row)"
                  >试听备注</el-button>

                <el-dropdown v-if="false" style="margin-left: 10px">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="!Boolean(scope.row.liveId)"
                      @click.native="addTestLiveRoom(scope.row)"
                      >新增测试直播间</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.liveId"
                      @click.native="addTestLiveRoom(scope.row)"
                      >重建测试直播间</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          style="float: right; margin-right: 20px"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 新建直播间 -->
    <add-live
      :show="dialogLive"
      :liveName="lessonLiveName"
      :liveData="liveData"
      :isTest="true"
      :lessonData="lessonLiveData"
      @close="(dialogLive = false), (liveData = null), (lessonLiveData = null)"
      @addLiveSave="addLiveSave"
      @editLiveSave="editLiveSave"
    ></add-live>

    <!-- 进入直播间 -->
    <join-live
      :show="dialogJionLive"
      @close="dialogJionLive = false"
      :liveRoomId="liveRoomId"
    ></join-live>

    <!-- 管理参加码 -->
    <code-manage
      :show="dialogCode"
      :liveRoomId="liveRoomId"
      @close="dialogCode = false"
    ></code-manage>
  </el-row>
</template>

<script>
// 默认展示列
const baseColSetting = [
  {
    label: "学员账号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "学员姓名",
    prop: "name",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "试听课内容",
    align: "center",
    state: true,
  },
  {
    label: "试听课上课时间",
    prop: "startTime",
    align: "center",
    state: true,
    width: "150",
  },
  {
    label: "授课老师",
    prop: "teacher",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "试听状态",
    prop: "tryStatus",
    state: true,
    align: "center",
    width: "100",
  },
];

import {
  listTryStuInfo,
  editTryStu,
  addAudionBlive,
  linkLive,
} from "@/api/frontbusiness";
import { delLessonStudent } from "@/api/senate/class";

import fileHeader from "./file-header";
import TextButton from "@/components/text-button";
import addLive from "@/components/senate/add-live";
import joinLive from "@/components/senate/detail-pane/components/join-live";
import codeManage from "@/components/senate/detail-pane/components/code-manage";

import getTryStatusColor from '@/mixins/getStateColor'
import { formatDateYMD } from '@/utils/formatDate';
export default {
  name: "auditionRecord",
  mixins: [getTryStatusColor],
  components: { fileHeader, TextButton, addLive, joinLive, codeManage},
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  watch: {
    $route(to, from) {
      if(to.query.typeDede !== undefined && to.query.typeDede == 'submitSuccess') {
        this.listTryStuInfo();
      }
    }
  },
  data() {
    return {
      typeList: [
        {
          name: "已预约",
        },
        {
          name: "承诺到访",
        },
        {
          name: "已试听",
        },
        {
          name: "未出勤",
        },
      ],
      lessonLiveName: "",
      liveData: null,
      lessonLiveData: {},
      lessonLive: true,
      dialogLive: false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      queryParam: {},
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      tableKey: 1,
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      studentIds: [],
      liveRoomId: "",
      dialogJionLive: false,
      dialogCode: false,
      dialogshow: false,
    };
  },
  created() {
    const end = new Date();
    const start = new Date();
    const weekday = start.getDay();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1));
    end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
    this.queryParam.minStartTime = [formatDateYMD(start),formatDateYMD(end)][0];
    this.queryParam.maxStartTime = [formatDateYMD(start),formatDateYMD(end)][1];
    this.listTryStuInfo();
  },

  methods: {
    datetTimeGet(val,data){
      this.queryParam.minStartTime =val
      this.queryParam.maxStartTime = data
    },
    updateSeleShowTeacher(val){
      this.queryParam.teacher =val
    },
    updateSeleShowCourse(val){
      this.queryParam.courseUnitTitle =val
    },
    updateSeleShowStatus(val){
      this.queryParam.tryStatus =val
    },
    updateSeleShowName(val){
      this.queryParam.loginName =val
    },
    LookTask(){
      this.$router.push({
        path: "/teacher/tryLessonDetail/",
        query: {
        }
      });
      setTimeout(()=> {
        let thembArray = this.$store.state.tagsView.visitedViews
        thembArray.forEach((item,index) => {
          if(item.path === '/education/listenStu-manage') {
            this.swapArray(thembArray,index,thembArray.length-2)
          }
        })
      },200)
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    findHasAutho(msg) {
      let perms = JSON.parse(localStorage.getItem("perms"));
      const isBoolean = perms.some((item) => item === msg);
      return isBoolean;
    },

    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },

    insertMark(row) {
      // if(row.tryStatus!='已试听'){
      //   this.$message.warning("未试听,暂不可备注！");
      //   return;
      // }
      this.$prompt("请输入课堂表现", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.tryMark,
      })
        .then(({ value }) => {
          if (value === "") {
            window.$msg("内容不能为空", 2);
            return false;
          }
          this.editTryStu(row, 2, value);
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    async delLessonStudent(row) {
      const res = await delLessonStudent({
        lessonId: row.lessonId,
        studentIds: [row.studentId],
      });
      if (res && res.state === "success") {
        window.$msg("取消成功");
        this.listTryStuInfo();
      }
    },

    async editTryStu(row, flag, message) {
      const res = await editTryStu({
        lessonId: row.lessonId,
        studentId: row.studentId,
        tryStatus: flag === 1 ? "承诺到访" : "",
        tryMark: message,
      });
      if (res && res.state === "success") {
        window.$msg("编辑成功");
        this.listTryStuInfo();
      }
    },

    async listTryStuInfo() {
      const response = await listTryStuInfo({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        teacher: this.queryParam.teacher ? this.queryParam.teacher : "",
        classroom: this.queryParam.classroom ? this.queryParam.classroom : "",
        courseUnitTitle: this.queryParam.courseUnitTitle ? this.queryParam.courseUnitTitle : "",
        consultant: this.queryParam.consultant
          ? this.queryParam.consultant
          : "",
        minStartTime:this.queryParam.minStartTime ? this.queryParam.minStartTime + " 00:00:00": "",
        maxStartTime:this.queryParam.maxStartTime ? this.queryParam.maxStartTime + " 23:59:59" : "",
        stuLoginName:this.queryParam.stuLoginName ? this.queryParam.stuLoginName : "",
        loginName: this.queryParam.loginName ? this.queryParam.loginName : "",
        // campusId: this.queryParam.campus_id ? this.queryParam.campus_id : '',
        tryStatus: this.queryParam.tryStatus ? this.queryParam.tryStatus : "",
      });
      this.dataList = response.body.list;
      this.pagination.total = response.body.total;
    },

    confirmGet(row, flag) {
      let message = "";
      if (flag === "confirm") {
        message = "已经和家长确定学生可以到访试听?";
      } else {
        message =
          "确认取消" + row.name + "[" + row.loginName + "]的试听排课吗?";
      }
      this.$confirm(message, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (flag === "confirm") {
            this.editTryStu(row, 1, "");
          } else {
            this.delLessonStudent(row);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    // 搜索/重置
    search(data) {
      if(data.isTrusted){

      }else{
        this.queryParam = { ...data };
      }

      this.pagination.currentPage = 1;
      this.listTryStuInfo();
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.listTryStuInfo();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.listTryStuInfo();
    },

    addTestLiveRoom(row) {
      this.studentIds = [row.studentId];
      this.lessonLiveName = `${row.course} ${row.startTime.substring(0, 19)}`;
      this.lessonLiveData = row;
      this.dialogLive = true;
    },

    addLiveSave(data) {
      let classUserLiveRooms = [];
      this.studentIds.forEach((item) => {
        classUserLiveRooms.push({
          userId: item,
        });
      });
      addAudionBlive({
        title: data.title,
        start_time: `${data.startTime}`,
        end_time: `${data.endTime}`,
        type: data.type,
        max_users: data.type === 4 ? data.max_users : null,
        max_backup_users: data.type === 4 ? data.max_backup_users : null,
        classUserLiveRooms: classUserLiveRooms,
        source: data.source,
      }).then((res) => {
        if (res.state === "success") {
          linkLive({
            lessonId: data.lesson_id,
            studentId: this.studentIds[0],
            liveId: res.body.id,
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("新增成功");
              this.listTryStuInfo();
            }
          });
        }
      });
    },

    jumpLive(row) {
      this.liveRoomId = row.liveId;
      this.dialogJionLive = true;
    },

    codeManage(row) {
      this.liveRoomId = row.liveId;
      this.dialogCode = true;
    },

    // 重建
    rebuild(row) {
      console.log("rebuild", row);
    },

    editLiveSave(data) {
      console.log("editLiveSave", data);
    },
  },
};
</script>

<style lang="scss">
@import "../../../style/table.scss";
/deep/ .el-card__body {
  padding: 0 10px;
}
/deep/ .el-table__row .is-center {
  padding: 20px 0;
}
</style>
