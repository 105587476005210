<template>
  <el-row class="table" >
  <el-tabs v-model="activeName" >
    <el-tab-pane label="试听记录" name="first" v-if="findPerms('forntbusiness:auditionRecord')">
      <audition-record ref="record"></audition-record>
      <!-- v-if="isShow" @show='show' -->
    </el-tab-pane>
    <el-tab-pane label="试听课表" name="second" v-if="findPerms('forntbusiness:auditionLesson')">
      <audition-lesson ref="lesson" ></audition-lesson>
    </el-tab-pane>
  </el-tabs>
  <!-- <add-adudition
    v-if="!isShow"
    @listTryLesson = 'search'
    @lookBack='lookBack'
  ></add-adudition> -->
  </el-row>
</template>

<script>
  import auditionRecord from '@/components/frontbusiness/auditionManage/auditionRecord'
  import auditionLesson from '@/components/frontbusiness/auditionManage/auditionLesson'
  import addAdudition from "@/components/frontbusiness/auditionManage/addAdudition";
  export default {
    data() {
      return {
        activeName: 'first',
        record:"",
        lesson:"",
        statistic:"",
        // isShow:true
      };
    },
    components: {
      auditionRecord,
      auditionLesson,
      addAdudition
    },
    mounted() {
      this.$refs.record.listTryStuInfo()
    },
    methods:{
      findPerms (key) {
        let perms = JSON.parse(localStorage.getItem('perms'))
        return perms.some(item => item === key || item === 'admin:all')
      },

      // lookBack(data){
      //   // this.isShow = !data;
      // },
      // search(){
      //   this.$refs.record.listTryStuInfo()
      // },
      // show(data){
      //   // this.isShow = !data
      // }
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../style/table.scss';
</style>
