<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="taskTitle"></span>
    </div>
    <!-- 步骤条 -->
    <el-steps :active="active" align-center finish-status="success" simple>
      <el-step title="分配试听学员"></el-step>
      <el-step title="创建直播间"></el-step>
    </el-steps>

    <!-- footer -->
    <el-row slot="footer" v-if="seleTaskBtns">
      <template>
        <!-- <el-button size="small" @click="prev" v-if="active>1">上一步</el-button> -->
        <el-button size="small" @click="next">下一步</el-button>
        <el-button size="small" type="primary" @click="saveTask">保存</el-button>
      </template>
      <!-- <template>
        <el-button size="small" type="primary" @click="saveTask">保存</el-button>
      </template> -->
    </el-row>

  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute
 * @attribute
 * @function close 自定义弹窗关闭事件
 */



export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editLessonData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      taskTitle: '',
      active: 0,
      seleTaskBtns: true, // 底部按钮状态
      taskTypeNum: 0, // 任务类型 1-图文、2-外部链接、3-考试、4-编程题库 5-python外部链接 ,6-pdf ,7-视频
      dataObject: null
    }
  },
  methods: {
    openDialog () {
      if (this.editLessonData === null) {
        this.taskTypeNum = 1
        this.taskType = true
        this.seleTaskBtns = true
        this.active = 1
        this.taskTitle = '新增试听排课'
      } else {
        this.taskTitle = '编辑试听排课'
      }
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 下一步
    next () {
      if (this.active++ > 1) this.active = 1
    },

    // 上一步
    prev (status) {
      // 重置表单项
      this.formData = {}
      if (status) {
        this.taskTypeNum = 0
        // this.taskType = true
        // this.seleTaskBtns = false
        if (this.active-- < 1) this.active = 1
      } else {
        if (this.active-- < 1) this.active = 1
      }
    },

    saveTask() {}
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.task-list {
  margin: 35px 0;
}
.task-item {
  display: flex;
  flex-direction: column;
  height: 150px;
  background: #f4f6f8;
  border-radius: 4px;
  border: 3px solid #f4f6f8;
  box-sizing: border-box;
  padding-top: 28px;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: 3px solid #f5c319;
  }
  img {
    width: 32px;
    height: 32px;
  }
  p {
    font-size: 16px;
    margin-top: 25px;
  }
}

/deep/ .el-dialog {
  min-width: 888px;
}

/deep/ .el-dialog__footer {
  text-align: right;
}

/deep/ .el-table td {
  padding: 15px 0 !important;
}
/deep/ .el-table th {
  padding: 15px 0 !important;
  background: #fcfdff;
}
/deep/ .el-table th > .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #434343;
}
/deep/ .el-table .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #666;
}
</style>
