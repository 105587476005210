<template>
  <el-row class="table">
    <!-- 表头 -->
    <file-header
      @search="search"
      @clearQuery="queryParam = { seleShow: 'list' }"
      :ifTeacher="true"
      :ifClassRoom="true"
      :ifCourse="true"
      :ifTime="true"
      :ifShow="true"
      @updateSeleShow="updateSeleShow"
      @updateSeleShowTeacher='updateSeleShowTeacher'
      @updateSeleShowCourse='updateSeleShowCourse'
      @updateSeleShowStatus='updateSeleShowStatus'
      @updateSeleShowName='updateSeleShowName'
      @setTime='setTime'
    ></file-header>
    <!-- 表格 -->
    <el-card shadow="never" v-if="seleShow === 'list'">
      <div slot="header" class="flex">
        <span>试听课表</span>
        <div class="flex_1"></div>
        <!-- <el-radio-group v-model="seleShow" size="small" class="mr-15" @change="handleChange()">
          <el-radio-button label="list">列表</el-radio-button>
          <el-radio-button label="calendar">日历</el-radio-button>
        </el-radio-group> -->
        <!-- <el-button plain size="mini" @click="checkTime()" v-has="'class:roomFreeTime'">查看老师空闲时间</el-button> -->
        <!-- <el-button plain size="mini" @click="checkRoomTime()" v-has="'class:roomFreeTime'">查看教室空闲时间</el-button> -->
        <!-- <el-button plain size="mini" @click="newAudition()" v-has="'audition:online'">预约线上试听</el-button> -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
      </div>

      <div class="table-container">
        <el-table
          :key="tableKey"
          :data="dataList"
          class="tb-list"
          fit
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
          style="width: 100%"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '试听课内容'">
                <span>{{ scope.row.course}} /{{scope.row.courseUnitTitles.title }}</span>
              </template>
              <template v-if="item.label === '试听时间'">
                <span>{{ scope.row.startTime | formatDateStart }}</span>
                <span>{{ scope.row.endTime | formatDateEnd }}</span>
                <!-- <span>{{scope.row.startTime.substring(0, 19)}}</span> -->
              </template>
              <template v-else-if="item.label === '试听状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' +
                    ':' +
                    getStateColor(scope.row.lessonStatus)
                  "
                ></span>
                <span>{{ scope.row.lessonStatus }}</span>
              </template>
              <template v-else-if="item.label === '试听人数'">
                <span
                  style="
                    text-decoration: underline;
                    color: #1c6ca1;
                    cursor: pointer;
                  "
                  @click="queryStudent(scope.row)"
                >
                  {{ scope.row.memberCount }}
                  <!-- /{{ scope.row.capacity }} -->
                </span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  v-has="'frontbusiness:lessonEdit'"
                  type="text"
                  @click="gotoEditLesson(scope.row)"
                  >编辑</el-button
                >
                <el-dropdown v-if="false" style="margin-left: 25px">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="
                        Boolean(scope.row.liveId) &&
                        findHasAutho('frontbusiness:lessonLive')
                      "
                      @click.native="jumpLive(scope.row)"
                      >去直播</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="Boolean(scope.row.liveId)"
                      @click.native="codeManage(scope.row)"
                      >管理参加码</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="
                        !Boolean(scope.row.liveId) &&
                        findHasAutho('frontbusiness:lessonLive')
                      "
                      @click.native="addLive(scope.row)"
                      >新增直播间</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="Boolean(scope.row.liveId)"
                      @click.native="addLive(scope.row)"
                      >重建直播间</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="
                        Boolean(scope.row.liveId) &&
                        findHasAutho('frontbusiness:lessonLiveEdit')
                      "
                      @click.native="getLiveRoomtnById(scope.row)"
                      >编辑直播间</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="
                        Boolean(scope.row.liveId) &&
                        findHasAutho('frontbusiness:lessonLiveDelete')
                      "
                      @click.native="deleteLive(scope.row)"
                      >删除直播间</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="float: right; margin-right: 20px"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 日历试图 -->
    <lesson-calendar
      v-if="seleShow !== 'list'"
      :calendarList="calendarList"
      @updateView="listTryLesson"
    ></lesson-calendar>


    <add-adudition-detail
      :show="addAduditionDetailDialog"
      :aduditionObject="aduditionObject"
      @close="addAduditionDetailDialog = false"
      @aduditionDetail="listTryLesson"
    ></add-adudition-detail>
    <adudition-lesson-student
      :show="lessonStudentDialog"
      :lessonId="lessonId"
      @close="lessonStudentDialog = false"
    ></adudition-lesson-student>
    <!-- 新建直播间 -->
    <add-live
      :show="dialogLive"
      :liveName="lessonLiveName"
      :liveData="liveData"
      :lessonData="lessonLiveData"
      @close="(dialogLive = false), (liveData = null), (lessonLiveData = null)"
      @addLiveSave="addLiveSave"
      @editLiveSave="editLiveSave"
    ></add-live>

    <code-manage
      :show="dialogCode"
      :liveRoomId="liveRoomId"
      @close="dialogCode = false"
    ></code-manage>
    <!-- 进入直播间 -->
    <join-live
      :show="dialogJionLive"
      @close="dialogJionLive = false"
      :liveRoomId="liveRoomId"
    ></join-live>
    <!-- 预约试听新流程 -->
    <new-audition
      :show="newAuditionDialog"
      @close="newAuditionDialog = false"
    ></new-audition>

    <!-- 查看老师空闲时间 -->
    <check-time :show="timeDialog" @close="timeDialog = false"></check-time>

    <!-- 查看教室空闲时间 -->
    <room-time
      :show="timeRoomDialog"
      @close="timeRoomDialog = false"
    ></room-time>
  </el-row>
</template>

<script>
// 默认展示列
const baseColSetting = [
  {
    label: "试听时间",
    prop: "startTime",
    state: true,
    align: "center",
    sortable: true,
    width: "150",
  },
  {
    label: "授课老师",
    prop: "teacher",
    state: true,
    align: "center",
  },
  {
    label: "试听课内容",
    state: true,
    align: "center",
  },
  {
    label: "试听状态",
    prop: "lessonStatus",
    state: true,
    align: "center",
  },
  {
    label: "试听人数",
    state: true,
    align: "center",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "100",
  },
];

import { listTryLesson } from "@/api/frontbusiness";
import {
  insertClassBliveRoom,
  deleteClassBliveRoomById,
  getLiveRoomtnById,
  updateClassBliveRoomById,
} from "@/api/senate/class";
import addLive from "@/components/senate/add-live";
import codeManage from "@/components/senate/detail-pane/components/code-manage";
import joinLive from "@/components/senate/detail-pane/components/join-live";
import aduditionLessonStudent from "@/components/frontbusiness/aduditionLessonStudent";
import addAduditionDetail from "@/components/frontbusiness/auditionManage/addAduditionDetail";
import fileHeader from "./file-header";
import TextButton from "@/components/text-button";
import lessonCalendar from "./lesson-calendar";
import { formatDate } from "@/utils/formatDate";
import newAudition from "@/components/frontbusiness/auditionManage/newAudition";
import CheckTime from "@/components/senate/check-time";
import roomTime from "@/components/senate/room-time";
import { formatDateYMD } from '@/utils/formatDate';

export default {
  name: "auditionLesson",
  components: {
    aduditionLessonStudent,
    addAduditionDetail,
    addLive,
    codeManage,
    joinLive,
    fileHeader,
    TextButton,
    lessonCalendar,
    newAudition,
    CheckTime,
    roomTime,
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  data() {
    return {
      dialogCode: false,
      operateFlag: true,
      liveRoomId: "",
      addAduditionDetailDialog: false,
      lessonStudentDialog: false,
      aduditionObject: {},
      lessonId: "",
      dialogshow: false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      lessonLiveName: "",
      liveData: null,
      lessonLiveData: {},
      lessonLive: true,
      dialogLive: false,
      dialogJionLive: false,
      timeDialog: false,
      timeRoomDialog: false,
      queryParam: {
        seleShow: "list",
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      tableKey: 1,
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      seleShow: "list",
      calendarList: [], // 日历数据
      newAuditionDialog: false,
      isNewAdudition: false,
      fromType: "",
      orderBy:'l.start_time',

    };
  },
  created() {
    const end = new Date();
    const start = new Date();
    const weekday = start.getDay();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1));
    end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
    this.queryParam.time = [formatDateYMD(start), formatDateYMD(end)];
  },

  mounted() {
    if (
      localStorage.getItem("perms") != null &&
      localStorage.getItem("perms") != undefined
    ) {
      if (
        JSON.stringify(localStorage.getItem("perms")).indexOf(
          "frontbusiness:lessonEdit "
        ) == -1 &&
        JSON.stringify(localStorage.getItem("perms")).indexOf(
          "frontbusiness:lessonLive"
        ) == -1
      ) {
        this.operateFlag = false;
      }
    }
    this.listTryLesson();
  },
  methods: {
    findHasAutho(msg) {
      let perms = JSON.parse(localStorage.getItem("perms"));
      const isBoolean = perms.some((item) => item === msg);
      return isBoolean;
    },
    setTime(val){
      this.queryParam.time = val
    },
    updateSeleShow(val) {
      this.seleShow = val;
    },
    updateSeleShowTeacher(val){
      this.queryParam.teacher =val
    },
    updateSeleShowCourse(val){
      this.queryParam.courseUnitTitle =val
    },
    updateSeleShowStatus(val){
      this.queryParam.teacher =val
    },
    updateSeleShowName(val){
      this.queryParam.teacher =val
    },
    async getLiveRoomtnById(row) {
      this.lessonLiveData = row;
      this.liveRoomId = row.liveId;
      // const res = await getLiveRoomtnById({
      //   liveRoomId: row.liveId
      // })
      this.liveData = row;
      this.lessonLive = true;
      this.dialogLive = true;
    },
    // 列表排序
      tableSortAll(column) {
        switch (column.order) {
          case 'ascending':
            this.orderBy = 'l.start_time'
            break
          case 'descending':
            this.orderBy = 'l.start_time desc'
        }
        this.listTryLesson();
      },
    deleteLive(row) {
      this.$confirm("是否删除当前直播间", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await deleteClassBliveRoomById({
          liveRoomId: row.liveId,
        });
        window.$msg("删除成功");
        this.listTryLesson();
      });
    },
    jumpLive(row) {
      this.liveRoomId = row.liveId;
      this.dialogJionLive = true;
    },
    codeManage(row) {
      this.liveRoomId = row.liveId;
      this.dialogCode = true;
    },

    async addLiveSave(data) {
      await insertClassBliveRoom({
        title: data.title,
        start_time: data.startTime,
        end_time: data.endTime,
        type: data.type,
        max_users: data.type === 4 ? data.max_users : null,
        max_backup_users: data.type === 4 ? data.max_backup_users : null,
        lesson_id: data.lesson_id,
        source: data.source,
      });
      window.$msg("新增成功");
      this.listTryLesson();
    },

    async editLiveSave(data) {
      await updateClassBliveRoomById({
        id: data.id,
        title: data.title,
        start_time: data.startTime,
        end_time: data.endTime,
        type: data.type,
        max_users: data.type === 4 ? data.max_users : null,
        max_backup_users: data.type === 4 ? data.max_backup_users : null,
        lesson_id: data.lesson_id,
        source: data.source,
      });
      window.$msg("编辑成功");
      this.listTryLesson();
    },
    addLive(row) {
      this.lessonId = row.lessonId;
      this.lessonLiveName = `${row.course} ${row.startTime.substring(0, 19)}`;
      this.lessonLiveData = row;
      this.dialogLive = true;
    },
    gotoEditLesson(row) {
      if (row.lessonStatus === "已上课") {
        window.$msg("已上课排课不允许更改排课信息", 2);
        return false;
      }
      this.aduditionObject = row;
      this.addAduditionDetailDialog = true;
    },

    queryStudent(row) {
      if (
        row.memberCount === 0 ||
        row.memberCount === undefined ||
        row.memberCount === null
      ) {
        window.$msg("此排课中暂无学员", 2);
        return false;
      }
      this.lessonId = row.lessonId;
      this.lessonStudentDialog = true;
    },

    // 查看老师剩余时间
    checkTime() {
      this.timeDialog = true;
    },

    // 查看教室空闲时间
    checkRoomTime() {
      this.timeRoomDialog = true;
    },

    async listTryLesson() {
      this.calendarList = [];
      const res = await listTryLesson({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        teacher: this.queryParam.teacher ? this.queryParam.teacher : "",
        classroom: this.queryParam.classroom ? this.queryParam.classroom : "",
        courseUnitTitle: this.queryParam.courseUnitTitle ? this.queryParam.courseUnitTitle : "",
        // campusId: this.queryParam.campus_id ? this.queryParam.campus_id : '',
        minStartTime: this.queryParam.time  ?  this.queryParam.time[0] + " 00:00:00":  "" ,
        maxStartTime:   this.queryParam.time  ? this.queryParam.time[1] + " 23:59:59":"",
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
      res.body.list.forEach((ele, idx) => {
        this.calendarList.push({
          ...ele,
          title: this.setTitle(ele),
          id: ele.lessonId,
          start: ele.startTime.substring(0, 19),
          end: ele.endTime ? ele.endTime.substring(0, 19) : "",
          color: ele.lessonStatus === "已上课" ? "#BAD8E7" : "#BBBDBE",
        });
      });
    },

    // 设置标题
    setTitle(ele) {
      const start = formatDate(new Date(ele.startTime), "hh:mm");
      const end = formatDate(new Date(ele.endTime), "hh:mm");
      let setTitle = "";
      if (ele.classInfo !== null) {
        setTitle = `${start}-${end} ${ele.course}`;
      } else {
        setTitle = `${start}-${end}` + "试听课";
      }
      return setTitle;
    },
    search(data) {
      if(data.isTrusted){

      }else{
        this.queryParam = { ...data };
      }
      // this.queryParam = { ...data };
      // this.queryParam.time = JOSN.parse(JSON.stringify(data.time))
      this.pagination.currentPage = 1;
      this.$nextTick(()=>{
        this.listTryLesson();
      })

    },

    getStateColor(state) {
      switch (state) {
        case "已上课":
          return "#008000";
        case "未上课":
          return "#FF0000";
        default:
          return "#f5c319";
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.listTryLesson();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.listTryLesson();
    },

    // newAudition() {
    //   this.isNewAdudition = true;
    //   this.dialogshow = true;
    //   this.fromType = "onlinePage";
    //   // this.newAuditionDialog = true
    // },
  },
};
</script>

<style lang="scss">
@import "../../../style/table.scss";
#el-button1{
    color: #FFFFFF;
    background-color: #f5c319;
    border-color: #f5c319;
}
</style>
